<script>
import Chart from "chart.js";
import { generateChart } from "vue-chartjs";

let activeDays = [];
let allChartDays = [];
let activeNulls = [];
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease);
    var ctx = this.chart.ctx;
    var chart = this.chart;
    ctx.save();
    let xAxis = chart.scales["x-axis-0"];
    let dataset = chart.data.datasets[0];
    ctx.strokeStyle = dataset.borderColor;

    // allChartDays = [
    //   new Date("May 16 2021"),
    //   new Date("May 17 2021"),
    //   new Date("May 18 2021"),
    //   new Date("May 19 2021"),
    //   new Date("May 20 2021"),
    //   new Date("May 21 2021"),
    //   new Date("May 22 2021"),
    //   new Date("May 23 2021"),
    //   new Date("May 24 2021"),
    //   new Date("May 25 2021"),
    //   new Date("May 26 2021"),
    //   new Date("May 27 2021"),
    //   new Date("May 28 2021"),
    //   new Date("May 29 2021"),
    //   new Date("May 30 2021"),
    //   new Date("May 31 2021"),
    // ];
    // activeDays = [
    //   {
    //     startDate: "May 16 2021",
    //     endDate: "May 18 2021",
    //   },
    //   {
    //     startDate: "May 21 2021",
    //     endDate: "May 27 2021",
    //   },
    //   {
    //     startDate: "May 29 2021",
    //     endDate: "May 31 2021",
    //   },
    // ];
    //console.log(activeDays)
    //console.log(allChartDays)
    //console.log('activeNulls', activeNulls);
    let allActiveIndexes = [];
    for (let i = 0; i < allChartDays.length; i++) {
      for (const range of activeDays) {
        if (
          (range.startDate, allChartDays[i] >= new Date(range.startDate)) &&
          (range.endDate, allChartDays[i] <= new Date(range.endDate))
        ) {
          allActiveIndexes.push(i);
        }
      }
    }
    //console.log('allActiveIndexes',allActiveIndexes)
    //console.log('chart',chart)
    let Inactives = [];
    for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
      if (!allActiveIndexes.includes(i)) {
        Inactives.push(i);
      }
    }
    for (let i = 0; i < allActiveIndexes.length; i++) {
      //console.log('chmtav if-----------------', chart.data.datasets[0]._meta[Object.keys(chart.data.datasets[0]._meta)[0]].data[allActiveIndexes[i+1]]._model);

      if (
        chart.data.datasets[0] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i]] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i + 1]] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i]]._model.x &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i + 1]]._model.y &&
        i !== allActiveIndexes.length - 1 &&
        allActiveIndexes[i + 1] - allActiveIndexes[i] === 1 &&
        (chart.data.datasets[0].pointBackgroundColor[allActiveIndexes[i]] ===
          "#6D33A7" ||
          chart.data.datasets[0].pointBackgroundColor[
            allActiveIndexes[i + 1]
          ] === "#6D33A7")
      ) {
        let startX =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i]]._model.x;
        let startY =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i]]._model.y;
        let endX =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i + 1]]._model.x;
        let endY =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i + 1]]._model.y;
        (ctx = chart.ctx), ctx.save();
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.lineWidth = 3;
        ctx.strokeStyle = "#7737B7";
        ctx.stroke();
        ctx.restore();
        ctx.beginPath();
        ctx.strokeStyle =
          chart.data.datasets[0].pointBackgroundColor[allActiveIndexes[i]];
        ctx.arc(startX, startY, 3, 0, 2 * Math.PI);
        ctx.fillStyle =
          chart.data.datasets[0].pointBackgroundColor[allActiveIndexes[i]];
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.beginPath();
        ctx.strokeStyle =
          chart.data.datasets[0].pointBackgroundColor[allActiveIndexes[i + 1]];
        ctx.arc(endX, endY, 3, 0, 2 * Math.PI);
        ctx.fillStyle =
          chart.data.datasets[0].pointBackgroundColor[allActiveIndexes[i + 1]];
        ctx.fill();
        ctx.stroke();
        ctx.restore();
      }
      if (
        chart.data.datasets[0] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i]] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i + 1]] &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i]]._model.x &&
        chart.data.datasets[0]._meta[
          Object.keys(chart.data.datasets[0]._meta)[0]
        ].data[allActiveIndexes[i + 1]]._model.y &&
        i !== allActiveIndexes.length - 1 &&
        allActiveIndexes[i + 1] - allActiveIndexes[i] === 1 &&
        chart.data.datasets[0].pointBackgroundColor[allActiveIndexes[i]] ===
          "#C7C4C4" &&
        chart.data.datasets[0].pointBackgroundColor[allActiveIndexes[i + 1]] ===
          "#C7C4C4" &&
        chart.data.datasets[0].data[allActiveIndexes[i]] === 0 &&
        chart.data.datasets[0].data[allActiveIndexes[i + 1]] === 0
      ) {
        let startX =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i]]._model.x;
        let startY =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i]]._model.y;
        let endX =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i + 1]]._model.x;
        let endY =
          chart.data.datasets[0]._meta[
            Object.keys(chart.data.datasets[0]._meta)[0]
          ].data[allActiveIndexes[i + 1]]._model.y;
        (ctx = chart.ctx), ctx.save();
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.lineWidth = 3;
        ctx.strokeStyle = "#C7C4C4";
        ctx.stroke();
        ctx.restore();
      }
    }
    //console.log('activeNulls  ', activeNulls);
    //console.log("point color ", chart.data.datasets[0].pointBackgroundColor[5])

    let fillEmptyPeriods = function (arr, text) {
      if (text !== "No Data") {
        let periods = [];
        let startIndex = null;
        let endIndex = null;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i + 1] && arr[i + 1] - arr[i] === 1) {
            if (startIndex === null) {
              startIndex = arr[i];
            }
          } else if (endIndex === null && startIndex != null) {
            endIndex = arr[i];
            if (startIndex !== endIndex) {
              periods.push({
                start: startIndex,
                end: endIndex,
              });
              startIndex = null;
              endIndex = null;
            }
          }
          if (
            ((arr[i + 1] && arr[i + 1] - arr[i] != 1) || !arr[i + 1]) &&
            ((arr[i - 1] && arr[i] - arr[i - 1] != 1) || !arr[i - 1])
          ) {
            if (arr[i] - arr[i - 1] != 1) {
              periods.push({
                start: arr[i],
                end: arr[i],
              });
            }
          }
        }
        for (const period of periods) {
          if (dataset.data[period.start - 1] != null && period.start != 0) {
            period.start = period.start - 1;
          }
          if (dataset.data[dataset.data.length - 1] === null) {
            //dataset.data.push(null);
          }
          //if (period.end + 1 < dataset.data.length - 1) period.end = period.end + 1;
          if (
            period.start === period.end &&
            period.start < dataset.data.length - 1
          ) {
            let my_gradient = ctx.createLinearGradient(0, 0, 0, 180);
            my_gradient.addColorStop(0, "#E9E9E9");
            my_gradient.addColorStop(1, "rgba(225, 225, 225, 0)");
            ctx.fillStyle = my_gradient;
            ctx.fillRect(
              xAxis.getPixelForTick(period.start) + 6,
              chart.legend.bottom,
              xAxis.getPixelForTick(period.start + 1) -
                xAxis.getPixelForTick(period.start) -
                12,
              162,
            );
            ctx.font = "18px Gilroy";
            ctx.fillStyle = "#FA5C7C";
            ctx.fillText(
              text,
              xAxis.getPixelForTick(period.start) +
                (xAxis.getPixelForTick(period.start + 1) -
                  xAxis.getPixelForTick(period.start) -
                  70) /
                  2,
              90,
            );
          }
          if (period.start < period.end) {
            if (period.end === dataset.data.length - 1) {
              period.end = dataset.data.length - 2;
            }
            let my_gradient = ctx.createLinearGradient(0, 0, 0, 180);
            my_gradient.addColorStop(0, "#E9E9E9");
            my_gradient.addColorStop(1, "rgba(225, 225, 225, 0)");
            ctx.fillStyle = my_gradient;
            ctx.fillRect(
              xAxis.getPixelForTick(period.start) + 6,
              chart.legend.bottom,
              xAxis.getPixelForTick(period.end + 1) -
                xAxis.getPixelForTick(period.start) -
                12,
              162,
            );
            ctx.font = "18px Gilroy";
            ctx.fillStyle = "#FA5C7C";
            ctx.fillText(
              text,
              xAxis.getPixelForTick(period.start) +
                (xAxis.getPixelForTick(period.end + 1) -
                  xAxis.getPixelForTick(period.start) -
                  70) /
                  2,
              90,
            );
          }
        }
        //console.log(periods)
      }
    };

    if (activeNulls.length > 1) {
      fillEmptyPeriods(activeNulls, "No Data");
    }
    if (Inactives.length > 0) {
      fillEmptyPeriods(Inactives, "Inactive");
    }

    ctx.restore();
    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      let activePoint = this.chart.tooltip._active[0],
        x = activePoint.tooltipPosition().x,
        topY = activePoint.tooltipPosition().y + 5,
        bottomY = this.chart.scales["y-axis-0"].getPixelForValue(0);
      if (topY > bottomY) {
        topY = activePoint.tooltipPosition().y - 5;
        bottomY = this.chart.scales["y-axis-0"].getPixelForValue(0);
      }
      if (dataset.data[activePoint._index] !== 0) {
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([7, 7]);
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY - 2);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#EADCEC";
        ctx.stroke();
        ctx.restore();
      }
    }
  },
});
const CustomLine = generateChart("custom-line", "LineWithLine");

export default {
  extends: CustomLine,
  //mixins: [reactiveProp],
  //props: ['options'],
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    activeDays = this.chartdata.activeDays;
    allChartDays = this.chartdata.allChartDays;
    activeNulls = this.chartdata.nullIndexes;
    this.renderChart(this.chartdata.chartdata, this.options);
  },
};
</script>
