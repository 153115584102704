<template>
  <div style="overflow: hidden">
    <AppHeaderUser />
    <v-container id="ks_daily_container" fluid>
      <div class="d-flex align-center pt-7 pb-6 pl-3">
        <div
          id="ksDashboard-back-icon-2a3e8798"
          @click="$router.back()"
          :class="{ point: hover }"
          style="height: 35px"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <Images name="arrow.svg" />
        </div>
        <div class="d-flex ml-3">
          <Texting
            id="ksDashboard-header-text-4c89207e"
            @click.native="$router.back()"
            class="pointer"
            type="p1bold grey2"
            >KS Daily Analytics</Texting
          >
        </div>
        <div
          class="mx-4"
          style="height: 16px; border-left: 1px solid #eadcec"
        ></div>
        <div class="d-flex">
          <Texting type="p1 MainDark" style="opacity: 0.7"
            >Last Updated:
            {{
              referrersLastUpdate ? parseReferrersLastUpdate() : "..."
            }}</Texting
          >
          <v-tooltip
            content-class="last-updated"
            color="rgba(38,39,40,0.9)"
            max-width="295px"
            right
          >
            <template v-slot:activator="{ on }">
              <span style="height: 17px" v-on="on">
                <Images style="margin-left: 5px" name="interrogative.svg" />
              </span>
            </template>
            <Texting type="p1n mywhite" class="text-center"
              >The data is updated daily at 12:00 AM EST.<br />
              You can track conversions from the date of activation and filter
              them for previous days only, not for the current day.</Texting
            >
            <div
              class="arrow-tooltip-right"
              style="margin-left: 4px; margin-top: 25px"
            ></div>
          </v-tooltip>
        </div>
        <div
          v-if="project ? !project.isActiveProject : false"
          class="ml-auto"
          style="margin-right: 10px"
        >
          <Texting
            style="
              padding: 13px 22px;
              background-color: #eadcec;
              border-radius: 10px;
            "
            type="foralert dark-magenta"
          >
            <b>Warning:</b> Campaign is currently inactive. Dashboard reflect
            the data gathered when it was last active.
          </Texting>
        </div>
      </div>
      <v-row justify="space-between" align="center">
        <v-col cols="auto" class="pl-7 pt-0" style="padding-bottom: 2px">
          <Texting type="p3bold MainDark" style="opacity: 0.7">{{
            $route.params.projectTitle
          }}</Texting>
        </v-col>
        <v-col cols="auto" class="pt-0 pb-4">
          <v-row align="center">
            <v-col
              cols="8"
              sm="3"
              id="search-cont"
              class="py-0"
              style="max-width: 196px; min-width: 196px"
            >
              <v-text-field
                v-model="search"
                label="Search"
                color="#9E33E0"
                id="search_referrers"
                ref="search"
                @input="onChangeSearchValue"
                @click="onFocus"
                autocomplete="off"
                background-color="#ffffff"
                full-width
                outlined
                hide-details
              >
                <template v-slot:prepend-inner class="ma-0">
                  <Images name="input_search_icon.svg" class="ma-0" />
                </template>
                <template v-if="search" v-slot:append>
                  <Images
                    id="ksDashboard-search-input-clear-icon-804305a6"
                    class="pointer"
                    @click.native="
                      () => {
                        search = '';
                        onChangeSearchValue();
                      }
                    "
                    name="https://res.cloudinary.com/dyf7aejih/image/upload/v1640262682/images/Vector_vxrmhz.svg"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="auto" class="py-0" v-if="project">
              <v-menu
                id="ksDashboard-calendar-menu-b3235bf6"
                ref="date_menu"
                v-model="date_menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="slide-y-transition"
                z-index="10"
                nudge-bottom="10"
                offset-y
                left
                min-width="auto"
                content-class="date-menu-content"
              >
                <template v-slot:activator="{ on }">
                  <div
                    id="ksDashboard-calendar-menu-activator-d6c7b912"
                    @click="onClickCalendarFilter"
                    v-on="on"
                    class="d-flex pointer align-center"
                    style="
                      border: 1px solid #c7c4c4;
                      border-radius: 5px;
                      max-height: 28px;
                      padding: 7px 10px 7px 12px;
                      background-color: #ffffff;
                    "
                  >
                    <Texting
                      type="p1 grey1"
                      style="padding-right: 12px"
                      :style="{
                        color: date_menu ? '#9E33E0' : '#858585',
                        paddingTop: project.launchedAt ? '3px' : '',
                      }"
                      >{{
                        !date[0] ? launchDate() : date[0].replaceAll("-", "/")
                      }}</Texting
                    >
                    <Images
                      v-if="date.length === 2 || !date[0]"
                      name="https://res.cloudinary.com/dyf7aejih/image/upload/v1640588690/images/arrow-right_cquf7e.svg"
                      class="pr-3"
                    />
                    <Texting
                      v-if="date.length === 2 || !date[0]"
                      type="p1 grey1"
                      style="padding-top: 3px; padding-right: 12px"
                      :style="{ color: date_menu ? '#9E33E0' : '#858585' }"
                    >
                      {{
                        !date[1] ? currentDate() : date[1].replaceAll("-", "/")
                      }}
                    </Texting>
                    <Images
                      v-show="!date_menu"
                      name="https://res.cloudinary.com/dyf7aejih/image/upload/v1640288285/images/calendar_ept5dk.svg"
                    />
                    <Images
                      v-show="date_menu"
                      name="https://res.cloudinary.com/dyf7aejih/image/upload/v1640288299/images/calendar-icon-active_bvktqe.svg"
                    />
                  </div>
                </template>
                <v-date-picker
                  id="ksDashboard-date-picker-f7247cc2"
                  v-model="date"
                  :allowed-dates="allowedDates"
                  color="#9E33E0"
                  style="border-radius: unset"
                  no-title
                  range
                  scrollable
                >
                  <v-row>
                    <v-col cols="12" class="pa-0">
                      <v-divider
                        class="mx-5 mb-0 mt-1"
                        style="border-color: #e0e0e0"
                      ></v-divider>
                    </v-col>
                    <v-col
                      cols="auto"
                      style="
                        padding-right: 4px;
                        padding-bottom: 14px;
                        margin-left: auto;
                      "
                    >
                      <Button
                        id="ksDashboard-calendar-cancel-button-0fbfa96e"
                        texting="p2b grey2"
                        size="small"
                        :handleClick="closeCalendar"
                        type="cancel"
                        >cancel</Button
                      >
                    </v-col>
                    <v-col
                      cols="auto"
                      style="padding-bottom: 14px; padding-right: 24px"
                    >
                      <Button
                        id="ksDashboard-calendar-done-button-1d30981a"
                        b_type="submit"
                        texting="p2b mywhite"
                        size="small"
                        :handleClick="onClickCalendarDone"
                        type="primary"
                        >done</Button
                      >
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="
                (referrerCurrency !== '$' || converted) &&
                convertSwitch !== null
              "
              cols="auto"
              class="d-flex align-center py-0"
            >
              <Texting type="p1 MainDark" class="mr-2" style="opacity: 0.8"
                >Convert to USD $</Texting
              >
              <MySwitch
                id="Convert-switch-173f53f2"
                :outline="true"
                @change="onChange"
                :checked="convertSwitch"
              />
            </v-col>
            <v-col
              cols="auto"
              class="d-flex align-center py-0"
              style="padding-right: 24px"
            >
              <v-menu
                id="ksDashboard-export-menu-2f5a5ee0"
                ref="export_menu"
                v-model="export_menu"
                :close-on-content-click="true"
                transition="slide-y-transition"
                z-index="10"
                offset-y
                nudge-left="110"
                min-width="auto"
                content-class="export-menu-content"
              >
                <template v-slot:activator="{ on }">
                  <div
                    v-on="on"
                    @click="onClickExport"
                    class="pointer download-icon"
                  ></div>
                  <!--                  <Images
                    v-on="on"
                    @click.native="onClickExport"
                    class="pointer"
                    name="https://res.cloudinary.com/dyf7aejih/image/upload/v1640591075/images/Frame_194_blypfu.svg"
                  />-->
                  <!--                  <div
                    id="ksDashboard-export-menu-activator-48df08ac"
                    @click="onClickExport"
                    v-on="on"
                    class="d-flex pointer align-center"
                    style="
                      border: 1px solid #eadcec;
                      border-radius: 5px;
                      padding: 7px 16px 7px 16px;
                      background-color: #ffffff;
                    "
                  >
                    <Images
                      v-show="!export_menu"
                      name="export_data_icon.svg"
                      class="mr-2"
                    />
                    <Images
                      v-show="export_menu"
                      name="export_data_icon_clicked.svg"
                      class="mr-2"
                    />
                    <Texting
                      type="p1bold grey2"
                      :style="{ color: export_menu ? '#9E33E0' : '#858585' }"
                      >Export Data</Texting
                    >
                  </div>-->
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in export_types"
                    :key="index"
                    :id="'export-menu-item-' + index"
                    class="pointer export-menu-item"
                    @click="
                      item.title === 'Microsoft Excel (.xlsx)'
                        ? onexportXLSX()
                        : onexportPDF()
                    "
                  >
                    <v-list-item-title>
                      <Texting type="p1 grey1">{{ item.title }}</Texting>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--      <v-row align="center">
        <v-col cols="auto" class="d-flex pt-0 align-end pl-9">
          <Texting type="label0 silver"
            >Last Update:
            {{
              referrersLastUpdate ? parseReferrersLastUpdate() : "..."
            }}</Texting
          >
          <v-tooltip
            content-class="my-tooltip"
            color="rgba(38,39,40,0.9)"
            max-width="350px"
            top
          >
            <template v-slot:activator="{ on }">
              <span style="height: 17px" v-on="on">
                <Images style="margin-left: 5px" name="interrogative.svg" />
              </span>
            </template>
            <Texting type="p1n mywhite" class="text-center"
              >The data is updated daily at 12:00 AM EST.<br />
              You can track conversions from the date of activation and filter
              them for previous days only, not for the current day.</Texting
            >
            <div class="arrow-tooltip" style="margin-left: 30px"></div>
          </v-tooltip>
        </v-col>
      </v-row>-->
      <v-row justify="center" style="padding-bottom: 70px">
        <v-data-table
          dense
          :items.sync="referrers"
          :headers="headers"
          item-key="name"
          :search="search.trim()"
          :custom-filter="filterOnlyNames"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          hide-default-footer
          hide-default-header
          class="referrers-table"
          id="referrers_table"
        >
          <template v-slot:no-data>
            <Texting type="p1 MainDark" class="pb-10"
              >Loading... Please wait</Texting
            >
            <v-progress-linear
              indeterminate
              color="#9E33E0"
            ></v-progress-linear>
          </template>
          <template v-slot:header>
            <tr style="background-color: #24124d">
              <th
                v-for="(header, i) in headers"
                :key="i"
                class="pr-4"
                :style="{
                  paddingLeft: i === 0 ? '26px' : '16px',
                  textAlign: i > 1 ? 'right' : '',
                }"
                :width="i === 0 ? '35%' : '10%'"
              >
                <v-row
                  align="center"
                  :justify="i > 1 && i <= 5 ? 'end' : 'start'"
                >
                  <v-col
                    v-if="i > 1 && i < 5"
                    cols="auto"
                    class="pa-0"
                    style="margin-top: -9px"
                  >
                    <div
                      id="header-up-arrow-icon-a0397d66"
                      @click="toggleOrderUp(header.value)"
                      class="header-up-arrow"
                      :style="{
                        opacity:
                          sortBy === header.value && !sortDesc ? '1' : '',
                      }"
                    ></div>
                  </v-col>
                  <v-col
                    v-if="i > 1 && i < 5"
                    cols="auto"
                    class="pa-0"
                    style="margin-bottom: -3px; margin-left: -7px"
                  >
                    <div
                      id="header-down-arrow-icon-b11f3512"
                      @click="toggleOrderDown(header.value)"
                      class="header-down-arrow"
                      :style="{
                        opacity: sortBy === header.value && sortDesc ? '1' : '',
                      }"
                    ></div>
                  </v-col>
                  <v-col cols="auto" class="pl-2 d-flex">
                    <Texting
                      :id="'ksDashboard-header-' + header.value"
                      v-if="i > 1 && i < 5"
                      type="p1bold grey22"
                      @click.native="
                        sortBy = header.value;
                        sortDesc = !sortDesc;
                      "
                      class="pointer table-header-text"
                      >{{ header.text }}</Texting
                    >
                    <Texting
                      v-else-if="i !== 1"
                      type="p1bold grey22"
                      class="table-header-text"
                      style="white-space: nowrap"
                      >{{ header.text }}</Texting
                    >
                    <v-tooltip
                      content-class="ks-daily-tooltip"
                      color="rgba(38,39,40,0.9)"
                      max-width="220px"
                      class="text-center"
                      nudge-left="30"
                      bottom
                      v-if="i === 5"
                    >
                      <template v-slot:activator="{ on }">
                        <span style="height: 16px" v-on="on">
                          <Images
                            class="pb-2 pl-2"
                            :name="
                              tip_hover
                                ? 'https://res.cloudinary.com/dyf7aejih/image/upload/v1640765785/images/Tip_icon_hovered_efziyt.svg'
                                : 'https://res.cloudinary.com/dyf7aejih/image/upload/v1640765785/images/Tip_icon_j3dlzh.svg'
                            "
                            @mouseover.native="tip_hover = true"
                            @mouseleave.native="tip_hover = false"
                          />
                        </span>
                      </template>
                      <Texting type="p1 mywhite" class="py-1"
                        ><b>KS Daily</b> shows the activity of each referral
                        link day by day. Perkfection must be activated to be
                        able to collect this data.</Texting
                      >
                      <div
                        class="arrow-tooltip-up"
                        style="margin-left: 4px"
                      ></div>
                    </v-tooltip>
                    <v-menu
                      v-if="i === 1"
                      ref="ref_type_menu"
                      id="ref-type-menu-4ffd7776"
                      v-model="ref_type_menu"
                      :close-on-content-click="true"
                      transition="slide-y-transition"
                      z-index="10"
                      offset-y
                      min-width="128px"
                      nudge-left="12px"
                      nudge-bottom="4px"
                      content-class="export-menu-content"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                          id="ref_type_menu-activator"
                          v-on="on"
                          @click="ref_type_menu = true"
                          class="d-flex pointer align-center"
                        >
                          <Texting
                            type="p1bold grey22"
                            class="table-header-text types-menu-text"
                            >{{
                              ref_type_value ? ref_type_value : header.text
                            }}</Texting
                          >
                          <Images
                            name="https://res.cloudinary.com/dyf7aejih/image/upload/v1640700120/images/ks_daily_type_menu_icon_wdf7dg.svg"
                            style="margin-left: 6px; opacity: 0.8"
                            @click.stop="ref_type_menu = true"
                            :class="{
                              'type-menu-down-arrow': ref_type_menu,
                            }"
                          />
                          <!--                          <div
                            @click.stop="ref_type_menu = true"
                            :class="{
                              'header-up-arrow': !ref_type_menu,
                              'header-down-arrow': ref_type_menu,
                            }"
                            style="margin-left: 6px"
                            :style="{
                              borderColor: ref_type_menu ? '#9E33E0' : '',
                              marginTop: ref_type_menu ? '' : '3px',
                            }"
                          ></div>-->
                        </div>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in ref_types"
                          :key="index"
                          :id="'ref-type-menu-item-' + index"
                          class="pointer ref-type-menu-item"
                          @click="
                            ref_type_value = item.title;
                            openedRows = [];
                            ref_type_menu = false;
                          "
                        >
                          <v-list-item-title>
                            <Texting
                              type="p1 MainDark"
                              :style="{
                                opacity:
                                  item.title === ref_type_value ? '' : '0.7',
                              }"
                              >{{ item.title }}</Texting
                            >
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </th>
            </tr>
          </template>
          <template
            v-if="
              allItemsNow.length > 0 &&
              (isRangeSelected ||
                search !== '' ||
                ref_type_value !== 'All Types')
            "
            v-slot:body.prepend
          >
            <tr style="background-color: #f4f4f4">
              <td class="text-start">
                <Texting
                  type="p2b MainDark"
                  style="letter-spacing: 0.02em !important; display: inline"
                  >Total Calculated</Texting
                >
                <v-tooltip
                  content-class="my-tooltip"
                  color="rgba(38,39,40,0.9)"
                  class="text-center"
                  style="padding-top: 0"
                  right
                >
                  <template v-slot:activator="{ on }">
                    <span style="height: 16px; padding-top: 4px" v-on="on">
                      <Images
                        style="margin-left: 10px; position: relative; top: 3px"
                        :name="
                          tip_hover2
                            ? 'https://res.cloudinary.com/dyf7aejih/image/upload/v1641253438/images/Tip_n9vy7s.svg'
                            : 'https://res.cloudinary.com/dyf7aejih/image/upload/v1641253438/images/Tip_1_i3zjvp.svg'
                        "
                        @mouseover.native="tip_hover2 = true"
                        @mouseleave.native="tip_hover2 = false"
                      />
                    </span>
                  </template>
                  <Texting type="p1n mywhite"
                    ><b>Total</b> number is calculated based on the difference
                    from the previous day.</Texting
                  >
                  <div
                    class="arrow-tooltip-right"
                    style="margin-left: 4px"
                  ></div>
                </v-tooltip>
              </td>
              <td></td>
              <td class="text-end">
                <Texting type="p3small-3 purple-heart">{{
                  sumPledgedScore()
                }}</Texting>
              </td>
              <td class="text-end"></td>
              <td class="text-end">
                <Texting type="p3small-3 purple-heart">{{
                  referrerCurrency + parseInt(sumPledged()).toLocaleString()
                }}</Texting>
              </td>
              <td
                v-if="date.length === 2 && date[0] !== date[1] && !date_menu"
              ></td>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr
              @click="checkOpened(item) ? closeChart(item) : ''"
              :style="{ cursor: checkOpened(item) ? 'pointer' : 'unset' }"
            >
              <td class="text-start" style="border-bottom: 1px solid #f4f4f4">
                <Texting type="p2 MainDark" style="opacity: 0.8">{{
                  item.referrer
                }}</Texting>
              </td>
              <td class="text-start" style="border-bottom: 1px solid #f4f4f4">
                <Texting type="p2 MainDark" style="opacity: 0.8">{{
                  item.type
                }}</Texting>
              </td>
              <td
                class="text-start"
                style="
                  border-bottom: 1px solid #f4f4f4;
                  text-align: right !important;
                "
              >
                <Texting type="p2 MainDark" style="opacity: 0.8">{{
                  item.numberOfPledges
                }}</Texting>
              </td>
              <td
                class="text-start"
                style="
                  border-bottom: 1px solid #f4f4f4;
                  text-align: right !important;
                "
              >
                <Texting type="p2 MainDark" style="opacity: 0.8">{{
                  item.percentOfPledged + "%"
                }}</Texting>
              </td>
              <td
                class="text-start"
                style="
                  border-bottom: 1px solid #f4f4f4;
                  text-align: right !important;
                "
              >
                <Texting type="p2 MainDark" style="opacity: 0.8">{{
                  referrerCurrency + parseInt(item.pledged).toLocaleString()
                }}</Texting>
              </td>
              <td
                v-if="date.length === 2 && date[0] !== date[1] && !date_menu"
                style="border-bottom: 1px solid #f4f4f4"
              >
                <div
                  class="ml-auto mr-6 pointer d-none d-sm-flex"
                  :class="{
                    'chart-icon': !checkOpened(item),
                    'chart-icon-clicked': checkOpened(item),
                  }"
                  @click.stop="
                    !checkOpened(item) ? openChart(item) : closeChart(item)
                  "
                ></div>
              </td>
            </tr>
            <tr v-if="checkOpened(item)" class="d-none d-sm-table-row">
              <td colspan="6" style="border-bottom: none !important">
                <v-row style="position: relative">
                  <v-col cols="12" @mousemove="onChartHover(item)">
                    <LineChart
                      v-if="loaded"
                      :height="200"
                      css-classes="chart-container"
                      :chartdata="chartdata"
                      :options="options"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div
          class="pt-6 ml-5 ml-sm-auto mr-5"
          id="ref-table-pagination-wrapper"
          style="
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #9b9b9b !important;
            min-width: 400px;
          "
        >
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            color="#9E33E0"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-row>
    </v-container>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter";
import AppHeaderUser from "@/components/AppHeaderUser";
import Images from "@/components/MyImages";
import Texting from "@/components/Texting";
import Button from "@/components/Button";
import LineChart from "@/components/LineChart";
import XLSX from "xlsx";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { mapActions, mapState } from "vuex";
import router from "../plugins/router";
import myServices from "@/services/resource";
import axios from "axios";
import MySwitch from "@/components/MySwitch";
//import { mdiGoogleNearby } from "@mdi/js";
let curency = "";
let nullIndexes = [];

export default {
  components: {
    MySwitch,
    Button,
    Texting,
    Images,
    AppHeaderUser,
    AppFooter,
    LineChart,
  },
  data: () => ({
    convertSwitch: null,
    tip_hover: false,
    tip_hover2: false,
    scrollActive: false,
    openActive: false,
    loaded: false,
    chartdata: null,
    options: null,
    labels: null,
    allChartData: {},
    allChartDays: [],
    nullIndexes: [],
    width: 2,
    radius: 2,
    padding: 8,
    lineCap: "round",
    dataMinValue: null,
    fill: false,
    type: "trend",
    autoLineWidth: true,
    sortedArr: [],
    sortDesc: false,
    sortBy: "",
    hover: false,
    date: [],
    date_menu: false,
    export_menu: false,
    ref_type_menu: false,
    ref_type_value: "All Types",
    search: "",
    allItemsNow: [],
    project_id: "",
    project: null,
    isRangeSelected: false,
    export_types: [
      {
        title: "Microsoft Excel (.xlsx)",
      },
      {
        title: "PDF Document (.pdf)",
      },
    ],
    ref_types: [
      {
        title: "All Types",
      },
      {
        title: "Custom",
      },
      {
        title: "External",
      },
      {
        title: "Kickstarter",
      },
    ],
    all_ref_types: [
      {
        title: "All Types",
      },
      {
        title: "Custom",
      },
      {
        title: "External",
      },
      {
        title: "Kickstarter",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    openedRows: [],
  }),
  computed: {
    /*dateRangeText() {
      if (this.date.length === 2 && this.date[0] === this.date[1])
        return this.date[0];
      return this.date.join(" ~ ");
    },*/
    headers() {
      return [
        {
          text: "Referrer",
          align: "start",
          sortable: false,
          value: "referrer",
        },
        {
          text: "All Types",
          value: "type",
          filter: (value) => {
            if (!this.ref_type_value) return true;
            if (this.ref_type_value === "All Types") return true;
            if (this.ref_type_value === "Custom" && value === "Custom")
              return true;
            if (this.ref_type_value === "External" && value === "External")
              return true;
            if (
              this.ref_type_value === "Kickstarter" &&
              value === "Kickstarter"
            )
              return true;
          },
        },
        {
          text: "# of Pledges",
          value: "numberOfPledges",
        },
        {
          text: "% of Pledged",
          value: "percentOfPledged",
        },
        {
          text: "Pledged",
          value: "pledged",
        },
      ];
    },
    ...mapState({
      referrers: "referrers",
      referrerCurrency: "referrerCurrency",
      referrersLastUpdate: "referrersLastUpdate",
      referrersActiveDays: "referrersActiveDays",
      convertToUSD: "convertToUSD",
      converted: "converted",
    }),
    referrers: {
      get: function () {
        return this.$store.state.referrers;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    referrerCurrency: {
      get: function () {
        return this.$store.state.referrerCurrency;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    referrersLastUpdate: {
      get: function () {
        return this.$store.state.referrersLastUpdate;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    referrersActiveDays: {
      get: function () {
        return this.$store.state.referrersActiveDays;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    convertToUSD: {
      get: function () {
        return this.$store.state.convertToUSD;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    converted: {
      get: function () {
        return this.$store.state.converted;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  async mounted() {
    this.getOneProject(this.$route.params.projectTitle);
    this.loaded = false;
    // document.querySelector('#ks_daily_container').addEventListener('scroll', () => {
    //   console.log('scroll event fired!')
    // });
  },
  // destroyed() {
  //   window.removeEventListener('scroll', this.onScrollListen);
  // },
  watch: {
    page: function () {
      this.ref_type_menu = false;
      this.openedRows = [];
    },
    sortDesc: function () {
      this.openedRows = [];
      this.page = 1;
    },
    search: function (val) {
      let a = val;
      this.search = "";
      this.search = a;
      this.openedRows = [];
    },
    "$store.state.oneProject": function () {
      this.project = this.$store.state.oneProject;
      if (this.$route.params.projectTitle !== this.project.projectTitle) {
        router.push({
          path: "/404",
        });
      } else {
        this.$store
          .dispatch("getReferrers", this.project.projectId)
          .then(() => {
            this.allItemsNow = this.referrers;
            this.convertSwitch = this.convertToUSD;
          })
          .catch((err) => console.log(err));
      }
    },
    "$store.state.referrers": function () {
      this.referrers = this.$store.state.referrers;
      this.referrerCurrency = this.$store.state.referrerCurrency;
      this.referrersLastUpdate = this.$store.state.referrersLastUpdate;
      this.referrersActiveDays = this.$store.state.referrersActiveDays;
      this.convertToUSD = this.$store.state.convertToUSD;
      this.converted = this.$store.state.converted;
    },
    date: function (val) {
      if (val.length === 2 && new Date(val[0]) > new Date(val[1])) {
        let a;
        a = val[0];
        val[0] = val[1];
        val[1] = a;
      }
      if (val.length === 2 && val[0] === val[1]) {
        val.splice(-1, 1);
      }
      this.date = val;
    },
    ref_type_value: function (val) {
      this.allItemsNow = this.referrers;
      if (this.search !== "")
        this.allItemsNow = this.allItemsNow.filter((item) =>
          item.referrer
            .toLowerCase()
            .includes(this.search.trim().toLowerCase()),
        );
      if (val !== "All Types") {
        this.allItemsNow = this.allItemsNow.filter((item) => item.type === val);
      }
    },
    date_menu: function (val) {
      if (!val) {
        if (this.date.length === 2 && this.date[0] !== this.date[1]) {
          const index = this.headers.findIndex(
            (object) => object.text === "ks daily",
          );
          if (index === -1) {
            this.headers.push({
              text: "ks daily",
              value: "chart",
            });
          }
        } else {
          const index = this.headers.findIndex(
            (object) => object.text === "ks daily",
          );
          if (index !== -1) {
            this.headers.pop();
          }
        }
      }
    },
  },
  methods: {
    // onScrollListen() {
    //   this.scrollActive = true;
    //   console.log('scroll')
    // },
    currentDate() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "/" +
        today.getDate() +
        "/" +
        (today.getMonth() + 1);
      return date;
    },
    launchDate() {
      if (this.project) {
        if (this.project.launchedAt) {
          const firstDay = new Date(this.project.launchedAt);
          const date =
            firstDay.getFullYear() +
            "/" +
            firstDay.getDate() +
            "/" +
            (firstDay.getMonth() + 1);
          return date;
        } else {
          return "Launch date";
        }
      }
    },
    onChange(val) {
      this.convertSwitch = val;
      axios
        .put(`/api/referrer/convertToUSD/${this.project.projectId}`, {
          convertToUSD: val,
        })
        .then(() => {
          if (!(this.date.length > 0)) {
            this.$store.dispatch("getReferrers", this.project.projectId);
          } else {
            this.calendarFilter();
          }
        })
        .catch((error) => {
          myServices.showErrorMessage(this, error);
        });
    },
    closeCalendar() {
      this.date_menu = false;
    },
    getChartData(refName) {
      let chartDataAndDays = {
        chartdata: {
          labels: this.labels,
          datasets: [
            {
              type: "LineWithLine",
              fill: {
                value: null,
              },
              showLine: false,
              spanGaps: false,
              label: "",
              //data: [null, null, null, null, null, null, 100, 200, 0, 10, 500, null, null, null, null, null],
              //data: [5, 10, 5, 9, 0, 6, 20, 2, 10, null, null, null, 5, 2, 10, 10, 5, 2, 10, null, 20, 1, 2, 5, 12, 2, 5, 12, 2],
              data: this.allChartData[refName].numberOfPledges,
              backgroundColor: "transparent",
              borderColor: "#7737B7",
              pointBackgroundColor: "#6D33A7",
              pointBorderColor: "#6D33A7",
              pointHoverBackgroundColor: "#6D33A7",
              pointHoverBorderColor: "#6D33A7",
              pointHoverBorderWidth: 2,
              borderWidth: 2,
              hoverBackgroundColor: "#7737B7",
              tension: 0,
            },
          ],
        },
        activeDays: this.referrersActiveDays,
        allChartDays: this.allChartDays,
        nullIndexes: this.nullIndexes,
      };
      return chartDataAndDays;
    },
    getChartOptions(refName) {
      let pledges = this.allChartData[refName].pledges;
      curency = this.referrerCurrency;
      return {
        animation: {
          duration: 0,
        },
        maintainAspectRatio: false,
        scaleStartValue: "",
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              //min: 0,
              gridLines: {
                color: "#EADCEC",
                borderColor: "#EADCEC",
                //borderWidth: 2,
                drawBorder: false,
                tickMarkLength: 40,
                zeroLineColor: "#A7A0B8",
                zeroLineWidth: 2,
              },
              ticks: {
                min: this.dataMinValue >= 0 ? 0 : this.dataMinValue,
                fontSize: 11,
                fontFamily: "'Gilroy', sans-serif",
                fontColor: "#A7A0B8",
                fontStyle: "700",
                stepSize: 1,
                callback: function (value) {
                  if (value === 0) {
                    return "";
                  } else {
                    return value;
                  }
                },
              },
            },
          ],
          xAxes: [
            {
              //min: 0,
              gridLines: {
                display: true,
                drawTicks: true,
                drawBorder: true,
                color: "#A7A0B8",
                drawOnChartArea: false,
                tickWidth: 1,
                tickMarkLength: 7,
              },
              ticks: {
                padding: 5,
                fontSize: 11,
                fontFamily: "'Gilroy', sans-serif",
                fontColor: "#A7A0B8",
                fontStyle: "700",
                textStrokeWidth: 1,
                textStrokeColor: "#A7A0B8",
              },
            },
          ],
        },
        tooltips: {
          enabled: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return [
                data["datasets"][0]["data"][tooltipItem["index"]],
                pledges[tooltipItem["index"]],
              ];
            },
          },
          custom: function (tooltipModel) {
            //console.log('nullIndexes',nullIndexes)
            var tooltipEl = document.getElementById("chartjs-tooltip");
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.innerHTML = nullIndexes.includes(
                tooltipModel.dataPoints[0].index,
              )
                ? '<div style="padding: 7px 7px 7px 7px;background: #FFFFFF;border: 1px solid #EADCEC;border-radius: 8px;box-shadow: 0px 22.6763px 100.042px rgba(0, 0, 0, 0.07), 0px 8.27721px 36.5171px rgba(0, 0, 0, 0.0482987), 0px 4.01843px 17.7284px rgba(0, 0, 0, 0.0389404), 0px 1.96991px 8.69078px rgba(0, 0, 0, 0.0310596), 0px 0.778906px 3.43635px rgba(0, 0, 0, 0.0217013);"><table style="min-width: 95px"></table></div>'
                : '<div style="padding: 7px 13px 7px 13px;background: #FFFFFF;border: 1px solid #EADCEC;border-radius: 8px;box-shadow: 0px 22.6763px 100.042px rgba(0, 0, 0, 0.07), 0px 8.27721px 36.5171px rgba(0, 0, 0, 0.0482987), 0px 4.01843px 17.7284px rgba(0, 0, 0, 0.0389404), 0px 1.96991px 8.69078px rgba(0, 0, 0, 0.0310596), 0px 0.778906px 3.43635px rgba(0, 0, 0, 0.0217013);"><table style="min-width: 95px"></table></div>';
              document.body.appendChild(tooltipEl);
            }
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              tooltipEl.style.display = "none";
              return;
            } else if (tooltipModel.opacity === 1) {
              tooltipEl.style.display = "block";
            }
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add("no-transform");
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }
            if (tooltipModel.body) {
              if (nullIndexes.includes(tooltipModel.dataPoints[0].index)) {
                //var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);
                var innerHtml = "";

                bodyLines.forEach(function () {
                  var span =
                    '<span style="font-family: Gilroy;font-size: 12px;line-height: 7px;color: #595959;">No matching<br> records found</span>';
                  innerHtml +=
                    '<div style="text-align: center">' + span + "</div>";
                });

                var tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
              } else {
                //var titleLines = tooltipModel.title || [];
                bodyLines = tooltipModel.body.map(getBody);
                innerHtml = "";

                bodyLines.forEach(function (body) {
                  var data1 =
                    '<span style="font-weight: bold;font-family: Gilroy;font-size: 13px;line-height: 15px;color: #333333">' +
                    body[0] +
                    "</span>";
                  var data2 =
                    '<span style="font-weight: bold;font-family: Gilroy;font-size: 13px;line-height: 19px;color: #333333">' +
                    curency +
                    body[1] +
                    "</span>";
                  var span =
                    '<span style="font-family: Gilroy;font-size: 12px;line-height: 15px;color: #595959;"># of Pledges</span>';
                  innerHtml +=
                    '<tr><td style="white-space: nowrap;">' +
                    span +
                    '</td><td style="text-align: right">' +
                    data1 +
                    "</td></tr>";
                  span =
                    '<span style="font-family: Gilroy;font-size: 12px;line-height: 19px;color: #595959;">Pledged</span>';
                  innerHtml +=
                    '<tr style="white-space: nowrap"><td style="white-space: nowrap;">' +
                    span +
                    '</td><td style="text-align: right">' +
                    data2 +
                    "</td></tr>";
                });

                tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
              }
            }
            var position = this._chart.canvas.getBoundingClientRect();
            if (
              tooltipModel.dataPoints[0].label ===
                this._chart.config.data.labels[
                  this._chart.config.data.labels.length - 1
                ] ||
              (tooltipModel.dataPoints[0].label ===
                this._chart.config.data.labels[
                  this._chart.config.data.labels.length - 2
                ] &&
                this._chart.config.data.labels.length > 18)
            ) {
              tooltipEl.style.left =
                position.left +
                window.pageXOffset +
                tooltipModel.caretX -
                130 +
                "px";
              tooltipEl.style.top =
                position.top +
                window.pageYOffset +
                tooltipModel.caretY -
                70 +
                "px";
            } else {
              tooltipEl.style.left =
                position.left +
                window.pageXOffset +
                tooltipModel.caretX +
                10 +
                "px";
              tooltipEl.style.top =
                position.top +
                window.pageYOffset +
                tooltipModel.caretY -
                30 +
                "px";
            }
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.pointerEvents = "none";
          },
        },
      };
    },
    ActiveDays() {
      //let days = ['2021-05-16', '2021-05-31'];
      let days = this.date;
      let getDates = function (startDate, endDate) {
        let dates = [],
          allChartDays = [],
          currentDate = startDate,
          addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
          };
        while (currentDate <= endDate) {
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          let month = monthNames[currentDate.getMonth()];
          let day = currentDate.getDate();
          dates.push(day + " " + month);
          allChartDays.push(currentDate);
          currentDate = addDays.call(currentDate, 1);
        }
        return {
          dates,
          allChartDays,
        };
      };
      let dates = [];
      dates = getDates(new Date(days[0]), new Date(days[1]));
      this.allChartDays = dates.allChartDays;
      this.labels = dates.dates;
    },
    checkOpened(item) {
      return this.openedRows.includes(item);
    },
    onChartHover(item) {
      if (!this.openActive) {
        this.$nextTick(() => {
          //console.log('open chart')
          this.openChart(item);
        });
      }
    },
    getNoMatchingIndexes(chartdata) {
      let data = chartdata.chartdata.datasets[0].data;
      let allActiveIndexes = [];
      let activeNulls = [];
      let activeDays = chartdata.activeDays;
      let allChartDays = chartdata.allChartDays;
      // activeDays = [{
      //         startDate: 'May 16 2021',
      //         endDate: 'May 18 2021'
      //     },
      //     {
      //         startDate: 'May 21 2021',
      //         endDate: 'May 27 2021'
      //     },
      //     {
      //         startDate: 'May 29 2021',
      //         endDate: 'May 29 2021'
      //     },
      // ];
      // allChartDays = [new Date('May 16 2021'), new Date('May 17 2021'), new Date('May 18 2021'), new Date('May 19 2021'), new Date('May 20 2021'), new Date('May 21 2021'), new Date('May 22 2021'), new Date('May 23 2021'), new Date('May 24 2021'), new Date('May 25 2021'), new Date('May 26 2021'), new Date('May 27 2021'), new Date('May 28 2021'), new Date('May 29 2021'), new Date('May 30 2021'), new Date('May 31 2021')]

      for (let i = 0; i < chartdata.allChartDays.length; i++) {
        for (const range of activeDays) {
          if (
            (range.startDate, allChartDays[i] >= new Date(range.startDate)) &&
            (range.endDate, allChartDays[i] <= new Date(range.endDate))
          ) {
            allActiveIndexes.push(i);
          }
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i] === null && allActiveIndexes.includes(i)) {
          activeNulls.push(i);
        }
      }
      nullIndexes = activeNulls;
      this.nullIndexes = nullIndexes;
      return activeNulls;
    },
    openChart(item) {
      this.openActive = true;
      const data = {
        referrer: item.referrer,
        startDay: this.date[0],
        endDay: this.date[1],
      };

      axios
        .post(`/api/referrer/getPledges/${this.project.projectId}`, data)
        .then((res) => {
          this.allChartData[item.referrer] = res.data.data;
          if (!this.openedRows.includes(item)) this.openedRows.push(item);
          try {
            this.ActiveDays();
            let chartdata = this.getChartData(item.referrer);
            let nullIndexesNow = this.getNoMatchingIndexes(chartdata);
            nullIndexes = nullIndexesNow;
            let pointBackgroundColor = [];
            let data = this.getChartData(item.referrer).chartdata.datasets[0]
              .data;
            for (let i = 0; i < data.length; i++) {
              if (nullIndexesNow.includes(i)) {
                data[i] = 0;
                pointBackgroundColor.push("#C7C4C4");
              } else {
                pointBackgroundColor.push("#6D33A7");
              }
            }
            chartdata.chartdata.datasets[0].pointBackgroundColor =
              pointBackgroundColor;
            chartdata.chartdata.datasets[0].pointBorderColor =
              pointBackgroundColor;
            chartdata.chartdata.datasets[0].pointHoverBackgroundColor =
              pointBackgroundColor;
            chartdata.chartdata.datasets[0].pointHoverBorderColor =
              pointBackgroundColor;
            //chartdata.chartdata.datasets[0].borderColor = borderColor;
            //chartdata.chartdata.datasets[0].borderColor = pointBackgroundColor;
            chartdata.chartdata.datasets[0].data = data;
            chartdata.nullIndexes = nullIndexesNow;
            this.chartdata = chartdata;
            this.dataMinValue = Math.min(
              ...this.chartdata.chartdata.datasets[0].data,
            );
            this.options = this.getChartOptions(item.referrer);
            this.loaded = true;
            myServices.myAnalyticsEvents(
              this,
              "KS Daily",
              "Graph icon click",
              this.$route.params.projectTitle,
            );
            setTimeout(() => {
              this.openActive = false;
            }, 100);
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => console.log(err));
    },
    closeChart(item) {
      let index = this.openedRows.indexOf(item);
      if (index > -1) {
        this.openedRows.splice(index, 1);
      }
      delete this.allChartData[item.referrer];
    },
    onClickExport() {
      myServices.myAnalyticsEvents(this, "KS Daily", "Export data click");
      this.export_menu = true;
    },
    onFocus() {
      myServices.myAnalyticsEvents(this, "KS Daily", "Search input click");
    },
    onClickCalendarFilter() {
      myServices.myAnalyticsEvents(this, "KS Daily", "Calendar button click");
      this.date_menu = true;
    },
    onClickCalendarDone() {
      myServices.myAnalyticsEvents(
        this,
        "KS Daily",
        "Calendar Done button click",
      );
      this.$refs.date_menu.save(this.date);
      this.calendarFilter();
    },
    allowedDates(val) {
      for (const range of this.referrersActiveDays) {
        if (
          (range.startDate, val >= range.startDate) &&
          (range.endDate, val <= range.endDate)
        ) {
          return true;
        }
      }
    },
    onChangeSearchValue() {
      this.allItemsNow = this.referrers;
      if (this.ref_type_value !== "All Types")
        this.allItemsNow = this.allItemsNow.filter(
          (item) => item.type === this.ref_type_value,
        );
      this.allItemsNow = this.allItemsNow.filter((item) =>
        item.referrer.toLowerCase().includes(this.search.trim().toLowerCase()),
      );
    },
    sumPledgedScore() {
      let total = 0;
      for (let i = 0; i < this.allItemsNow.length; i++) {
        if (!isNaN(this.allItemsNow[i].numberOfPledges)) {
          total += this.allItemsNow[i].numberOfPledges;
        }
      }
      return total.toFixed();
    },
    sumPledged() {
      let total = 0;
      for (let i = 0; i < this.allItemsNow.length; i++) {
        if (!isNaN(this.allItemsNow[i].pledged)) {
          total += this.allItemsNow[i].pledged;
        }
      }
      return total.toFixed();
    },
    toggleOrderUp(value) {
      this.sortBy = value;
      this.sortDesc = false;
    },
    toggleOrderDown(value) {
      this.sortBy = value;
      this.sortDesc = true;
    },
    filterOnlyNames(value, search, item) {
      search = search ? search.toLowerCase() : search;
      return (
        typeof item.referrer === "string" &&
        item.referrer.toLowerCase().indexOf(search.trim()) !== -1
      );
    },
    parseReferrersLastUpdate() {
      let date = this.referrersLastUpdate;
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(2, 4);

      // day < 10 ? (day = `0${day}`) : day;
      // month < 10 ? (month = `0${month}`) : month;

      let time = this.referrersLastUpdate.split("T")[1].slice(0, 5);
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
      time = time.join("");

      return `${day}/${month}/${year} ${time} EST`;
    },
    onexportXLSX() {
      myServices.myAnalyticsEvents(this, "KS Daily", "Excel export click");
      let xlsxName;
      if (this.search) {
        this.search = this.search.trim();
        xlsxName = `${
          this.search.length > 29
            ? this.search.substring(0, 20) + "..."
            : this.search
        }`;
      }

      if (this.search && this.date.length === 1)
        xlsxName = `${xlsxName}:${this.date[0]}`;
      if (!this.search && this.date.length === 1) xlsxName = `${this.date[0]}`;

      if (this.search && this.date.length === 2)
        xlsxName = `${xlsxName}:${this.date[0]}-${this.date[1]}`;
      if (!this.search && this.date.length === 2)
        xlsxName = `${this.date[0]}-${this.date[1]}`;

      if (!this.search && !this.date.length) xlsxName = "Overall";

      const wb = XLSX.utils.book_new();

      if (this.sortBy !== "") {
        if (this.sortDesc)
          this.allItemsNow.sort((a, b) =>
            a[this.sortBy] < b[this.sortBy] ? 1 : -1,
          );
        else
          this.allItemsNow.sort((a, b) =>
            a[this.sortBy] > b[this.sortBy] ? 1 : -1,
          );
      }

      const xlsxArr = this.allItemsNow.map((ref) => ({
        Referrers: ref.referrer,
        Type: ref.type,
        "# of Pledges": ref.numberOfPledges,
        "% of Pledged": `${ref.percentOfPledged}%`,
        Pledged: `${this.referrerCurrency}${ref.pledged}`,
      }));

      if (
        this.allItemsNow.length > 0 &&
        (this.isRangeSelected ||
          this.search !== "" ||
          this.ref_type_value !== "All Types")
      ) {
        let pledged = Number(this.sumPledged());
        let numberOfPledges = Number(this.sumPledgedScore());

        let sum = {
          Referrers: "Total",
          Type: null,
          "# of Pledges": numberOfPledges,
          "% of Pledged": null,
          Pledged: `${this.referrerCurrency}${pledged}`,
        };

        xlsxArr.unshift(sum);
      }

      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(xlsxArr),
        `${xlsxName}`,
      );
      XLSX.writeFile(wb, `KS Daily Analytics.xlsx`);
    },
    onexportPDF() {
      myServices.myAnalyticsEvents(this, "KS Daily", "PDF export click");
      let doc = new jsPDF();

      if (this.sortBy !== "") {
        if (this.sortDesc)
          this.allItemsNow.sort((a, b) =>
            a[this.sortBy] < b[this.sortBy] ? 1 : -1,
          );
        else
          this.allItemsNow.sort((a, b) =>
            a[this.sortBy] > b[this.sortBy] ? 1 : -1,
          );
      }

      const pdfArr = this.allItemsNow.map((ref) => ({
        Referrers: ref.referrer,
        Type: ref.type,
        "# of Pledges": ref.numberOfPledges,
        "% of Pledged": `${ref.percentOfPledged}%`,
        Pledged: `${this.referrerCurrency}${ref.pledged}`,
      }));

      if (
        this.allItemsNow.length > 0 &&
        (this.isRangeSelected ||
          this.search !== "" ||
          this.ref_type_value !== "All Types")
      ) {
        let pledged = Number(this.sumPledged());
        let numberOfPledges = Number(this.sumPledgedScore());

        let sum = {
          Referrers: "Total",
          Type: null,
          "# of Pledges": numberOfPledges,
          "% of Pledged": null,
          Pledged: `${this.referrerCurrency}${pledged}`,
        };

        pdfArr.unshift(sum);
      }

      doc.autoTable({
        //columns: this.headers,
        columns: [
          {
            header: "Referrers",
            dataKey: "Referrers",
          },
          {
            header: "Type",
            dataKey: "Type",
          },
          {
            header: "# of Pledges",
            dataKey: "# of Pledges",
          },
          {
            header: "% of Pledged",
            dataKey: "% of Pledged",
          },
          {
            header: "Pledged",
            dataKey: "Pledged",
          },
        ],
        body: pdfArr,
      });

      doc.save("KS Daily Analytics.pdf");
    },
    calendarFilter() {
      if (!(this.date.length > 0)) return;
      let param;
      if (this.date.length === 2) {
        param = `${this.project.projectId}?startDate=${this.date[0]}&&endDate=${this.date[1]}`;
      } else if (this.date.length === 1) {
        param = `${this.project.projectId}?date=${this.date[0]}`;
      }

      this.$store
        .dispatch("getReferrers", param)
        .then(() => {
          this.allItemsNow = this.referrers;
          if (this.search !== "") {
            this.allItemsNow = this.allItemsNow.filter((item) =>
              item.referrer
                .toLowerCase()
                .includes(this.search.trim().toLowerCase()),
            );
          }
          if (this.ref_type_value !== "All Types") {
            this.allItemsNow = this.allItemsNow.filter(
              (item) => item.type === this.ref_type_value,
            );
          }
          this.isRangeSelected = true;
        })
        .catch((err) => console.log(err));
    },
    ...mapActions(["getReferrers"]),
    ...mapActions(["getOneProject"]),
  },
};
</script>

<style scoped>
.date-menu-content {
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.17);
  border-radius: 5px !important;
  background-color: #ffffff;
}

.export-menu-content {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25), 0px 15px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px !important;
  border: 1px solid #d1cdda;
}

.header-up-arrow {
  width: 5px;
  height: 5px;
  border-bottom: 2px solid #eadcec;
  border-left: 2px solid #eadcec;
  transform: rotate(135deg);
  cursor: pointer;
  opacity: 0.24;
  margin-right: 2px;
}

.header-up-arrow:hover {
  border-color: #eadcec;
  opacity: 1;
}

.header-down-arrow {
  width: 5px;
  height: 5px;
  border-bottom: 2px solid #eadcec;
  border-left: 2px solid #eadcec;
  transform: rotate(-45deg);
  cursor: pointer;
  opacity: 0.24;
}

.header-down-arrow:hover {
  border-color: #eadcec;
  opacity: 1;
}

.chart-container {
  padding-left: 30px;
  height: 200px;
  width: 93vw;
  overflow: hidden;
}

@media only screen and (max-width: 1193px) {
  .chart-container {
    width: 89vw;
  }
}

@media only screen and (max-width: 760px) {
  .chart-container {
    width: 85vw;
  }
}

.chart-icon {
  width: 22px;
  height: 22px;
  background: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1620634887/images/ks_daily_chart_icon_wte5tk.svg")
    no-repeat;
}

.chart-icon:hover {
  width: 22px;
  height: 22px;
  background: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1621508715/images/ks_daily_chart_icon_hover_dapwlq.svg")
    no-repeat;
}

.chart-icon-clicked {
  width: 22px;
  height: 22px;
  background: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1620655766/images/ks_daily_chart_icon_clicked_cpwce2.svg")
    no-repeat;
}
.download-icon {
  width: 32px;
  height: 28px;
  background: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1640591075/images/Frame_194_blypfu.svg  ")
    no-repeat;
}
.download-icon:hover {
  background: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1640592604/images/Frame_194_1_prssfg.svg")
    no-repeat;
}

.table-header-text {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  opacity: 0.8;
}
.table-header-text:hover {
  opacity: 1;
}
.types-menu-text {
  border-bottom: 1px dashed #ffffff;
}
.type-menu-down-arrow {
  transform: rotate(180deg);
}
.type-menu-down-arrow:hover {
  opacity: 1;
}

.ref-type-menu-item {
  min-height: 27px;
}
.ref-type-menu-item:hover {
  background-color: #f4f4f4;
}
.ks-daily-tooltip {
  margin-top: 10px;
  margin-left: -12px;
}
.last-updated {
  padding: 5px 5px;
}
</style>
